import styled from 'styled-components';

export const ContainerHolder = styled.div`
  display: flex;
  width: 100vw !important;
  height: 100vmin !important;
  /* background: #fff; */
  position: absolute;
  justify-content: space-between;

  .autoplay-progress {
    position: absolute;
    right: 24px;
    top: 5px;
    z-index: 999;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #00a859;
  }

  .autoplay-progress svg {
    --progress: 0;
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 999;
    width: 100%;
    height: 100%;
    stroke-width: 4px;
    stroke: #00a859;
    fill: none;
    stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
    stroke-dasharray: 125.6;
    transform: rotate(-90deg);
  }
`;

export const Container = styled.div`
  display: block;
  width: 100% !important;
  height: 100vmin !important;

  /* background: #fff; */
  background: #faf9f6;

  header {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 10px;
    z-index: 99;
    position: relative;

    h1 {
      color: #00a859;
      font-weight: 600;
    }
  } /* END HEADER */

  @media (max-width: 1020px) {
    h1 {
      color: #00a859;
      font-weight: 600;
      font-size: 2rem;
    }
  }
`;

export const TableContainer = styled.section`
  max-height: 100%;
  overflow-y: visible;
  overflow-x: hidden;
  margin-top: 0px;

  padding-left: 10px;
  padding-right: 10px;

  table {
    width: 100%;
    border-spacing: 0 8px;
    white-space: nowrap;
    justify-content: center;

    thead {
      height: 0px;
      margin-top: 0px;
    }

    th {
      text-align: center;
      color: #00a859;
      font-size: 1.5rem;
    }

    td {
      padding: 8px 0px;
      border: 0;

      /* background: #fff; */
      text-align: center;
      font-size: 1rem;
      font-weight: 600;

      text-overflow: ellipsis;
    }

    td:first-child {
      border-radius: 8px 0 0 8px;
    }

    td:last-child {
      border-radius: 0 8px 8px 0;
    }
  }

  @media (max-width: 1020px) {
    table {
      width: 100%;
      border-spacing: 0 8px;
      white-space: nowrap;
      justify-content: center;
      margin-top: 30px;

      thead {
        height: 0px;
        margin-top: 0px;
      }

      th {
        text-align: center;
        color: #00a859;
        font-size: 1.1rem;
      }

      td {
        padding: 8px 0px;
        border: 0;

        /* background: #fff; */
        text-align: center;
        font-size: 1rem;
        font-weight: 600;
      }
    }
  }
`;
