export const filaStatusPriority = {
  AENT: 0,
  ELIB: 1,
  ACKL: 2,
  PCAR: 3,
  AFAT: 4,
  ADOC: 5,
  NLIB: 6,
};

export const getFilaTextColorByStatus = (status: string) => {
  let color = '#171412';

  switch (status) {
    case 'ACKL':
      color = '#D22B2B';
      break;
    case 'PCAR':
      color = '#1a75ff';
      break;
    case 'AFAT':
      color = '#F28C28';
      break;
    case 'ADOC':
      color = '#ffcc00';
      break;
    case 'NLIB':
      color = '#00a859';
      break;
    default:
      color = '#171412';
      break;
  }

  return { color };
};
