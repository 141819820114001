/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';

import { FiCheckSquare } from 'react-icons/fi';

import moment from 'moment';
import { isWeekend } from 'date-fns';
import { ButtonGroup } from '../../../styles/customButtonGroup';

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import Modal from '../../Modal';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MaskedInput } from '../../Material/MaskedInput';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
}

interface IFormData {
  cpf: string;
  dataagendamento: Date;
  dataprevista: Date;
  agendamentoid: number;
}

function ModalChangeDate({ isOpen, setIsOpen }: IModalProps): ReactElement {
  const { addToast } = useToast();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IFormData>();

  const onSubmit: SubmitHandler<IFormData> = async (data: IFormData) => {
    // EXTRACT DATE
    // const formatDataAgendamento = String(data.dataagendamento);
    // const dayAgendamento = formatDataAgendamento.substring(0, 2);
    // const monthAgendamento = formatDataAgendamento.substring(3, 5);
    // const yearAgendamento = formatDataAgendamento.substring(6, 10);

    const formatDataPrevista = String(data.dataprevista);
    const dayPrevisto = formatDataPrevista.substring(0, 2);
    const monthPrevisto = formatDataPrevista.substring(3, 5);
    const yearPrevisto = formatDataPrevista.substring(6, 10);

    const timeNow = moment().format('HH:mm:ss');

    try {
      const checkWeekend = isWeekend(
        new Date(
          moment(`${yearPrevisto}-${monthPrevisto}-${dayPrevisto}`).format(
            'YYYY-MM-DD 00:00:00.000',
          ),
        ),
      );

      if (checkWeekend) {
        const agendamentoFdsFound = await api.get('/agendamentofds', {
          params: {
            data: `${yearPrevisto}-${monthPrevisto}-${dayPrevisto}`,
          },
        });

        if (!agendamentoFdsFound) {
          // Show Toast for error
          addToast({
            type: 'error',
            title: 'Alteração de datas.',
            description: `Não existe carregamento liberado para esta data.`,
          });
          return;
        }
      }

      // Find Agendamento
      const agendamentoFound = await api.get(
        `/agendamentos/byagendamentoid/${data.agendamentoid}`,
      );

      if (!agendamentoFound || agendamentoFound.data.agendamentoid === 0) {
        // Show Toast for error
        addToast({
          type: 'error',
          title: 'Alteração de datas.',
          description: `Agendamento não encontrado!`,
        });
        return;
      }

      // Find What Spot can be used for that day
      let groupProdutos;
      switch (agendamentoFound.data.produto) {
        case 1:
          groupProdutos = '1,2,5';
          break;
        case 2:
          groupProdutos = '1,2,5';
          break;
        case 3:
          groupProdutos = '3';
          break;
        case 4:
          groupProdutos = '4';
          break;
        case 5:
          groupProdutos = '1,2,5';
          break;
        default:
          break;
      }

      const foundSpot = await api.get(
        `/fila/spotscarregamento/${yearPrevisto}-${monthPrevisto}-${dayPrevisto}/${groupProdutos}`,
      );

      // IF status === 'A' then only update agendamento
      if (agendamentoFound.data.status === 'A') {
        // Update agendamento only
        await api.put(
          `/agendamentos/updatedata/${Number(
            agendamentoFound.data.agendamentoid,
          )}`,
          {
            data: `${yearPrevisto}-${monthPrevisto}-${dayPrevisto} ${timeNow}`,
            spot: Number(foundSpot.data),
          },
        );

        // Show Toast for success
        addToast({
          type: 'success',
          title: 'Alteração de datas.',
          description: `Agendamento alterado com sucesso!`,
        });

        // Close the modal
        setIsOpen();

        // reset form
        reset();
        return;
      }

      const foundFilaCarregamento = await api.get(
        `/fila/byfilaid/${agendamentoFound.data.filaid}`,
      );

      if (!foundFilaCarregamento.data) {
        // Show Toast for error
        addToast({
          type: 'error',
          title: 'Alteração de datas.',
          description: `Fila não encontrada, por favor verificar!`,
        });
        return;
      }

      if (
        foundFilaCarregamento.data.status === 'PCAR' ||
        foundFilaCarregamento.data.status === 'AFAT' ||
        foundFilaCarregamento.data.status === 'ADOC' ||
        foundFilaCarregamento.data.status === 'NLIB'
      ) {
        // Show Toast for error
        addToast({
          type: 'error',
          title: 'Alteração de datas.',
          description: `Processo de Carregamento já iniciado, não é possível alterar datas. Por favor verificar!`,
        });
        return;
      }

      // Update Agendamento new data
      await api.put(
        `/agendamentos/updatedata/${Number(
          agendamentoFound.data.agendamentoid,
        )}`,
        {
          data: `${yearPrevisto}-${monthPrevisto}-${dayPrevisto} ${timeNow}`,
          spot: Number(foundSpot.data),
        },
      );

      // Update Fila Carregamento New Data
      await api.put(`/fila/byfilaid/${foundFilaCarregamento.data.filaid}`, {
        data: `${yearPrevisto}-${monthPrevisto}-${dayPrevisto} ${timeNow}`,
        spot: Number(foundSpot.data),
      });

      // Find Carregamento
      const carregamentoIdFound = await api.get(
        `/carregamentos/byagendamentoid`,
        {
          params: {
            agendamentoid: agendamentoFound.data.agendamentoid,
          },
        },
      );

      if (carregamentoIdFound.data.length > 0) {
        // Find All Carregamentos por compartimentos by Carregamentoid
        const foundCarregamentos = await api
          .get('/carregamentos/bycarregamentoid', {
            params: {
              carregamentoid: Number(carregamentoIdFound.data.carregamentoid),
            },
          })
          .then(response => {
            return response.data;
          });

        // Updates each carregamento found by carregamento id
        foundCarregamentos.map(async carreg => {
          await api.put(`/carregamentos/byid/${carreg.id}`, {
            data: `${yearPrevisto}-${monthPrevisto}-${dayPrevisto} ${timeNow}`,
          });
        });
      }

      if (carregamentoIdFound.data.length === 0) {
        // Show Toast for success
        addToast({
          type: 'success',
          title: 'Alteração de datas.',
          description: `Agendamento e Fila alterados com sucesso!`,
        });

        // return;
      } else {
        // Show Toast for success
        addToast({
          type: 'success',
          title: 'Alteração de datas.',
          description: `Alteração realizada com sucesso!`,
        });
      }

      // Close the modal
      setIsOpen();

      // reset form
      reset();
    } catch (err: any) {
      console.log('ModalChangeDate', err);
      // Close the modal
      setIsOpen();

      // Show Toast for failure
      addToast({
        type: 'error',
        title: 'Erro ao alterar data.',
        description: `${err.response.data.message}`,
      });

      // reset form
      reset();
    }
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} onCloseModal={() => reset()}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '1rem',
          gap: '1rem',
        }}
      >
        <h1
          style={{
            fontWeight: '500',
            fontSize: '36px',
            lineHeight: '36px',
            marginBottom: '40px',
            color: '#00a859',
            textAlign: 'center',
          }}
        >
          Alterar Data Processo
        </h1>

        <MaskedInput
          {...register('dataagendamento', {
            required: 'Data agendada obrigatória.',
          })}
          control={control}
          type="date"
          name="dataagendamento"
          label="Data Agendada"
          onBlur={() => {}}
          error={!!errors.dataagendamento}
          autoComplete="off"
          sx={{ width: '237px' }}
        />

        <MaskedInput
          {...register('agendamentoid', {
            required: 'Agendamento ID obrigatorio.',
          })}
          control={control}
          type="none"
          name="agendamentoid"
          label="Agendamento ID"
          onBlur={() => {}}
          error={!!errors.agendamentoid}
          autoComplete="off"
          sx={{ width: '237px' }}
        />

        <MaskedInput
          {...register('cpf', { required: 'CPF obrigatório.' })}
          control={control}
          type="cpf"
          name="cpf"
          label="CPF Motorista"
          onBlur={() => {}}
          error={!!errors.cpf}
          autoComplete="off"
          sx={{ width: '237px' }}
        />

        <MaskedInput
          {...register('dataprevista', {
            required: 'Data prevista obrigatória.',
          })}
          control={control}
          type="date"
          name="dataprevista"
          label="Data Prevista"
          onBlur={() => {}}
          error={!!errors.dataprevista}
          autoComplete="off"
          sx={{ width: '237px' }}
        />

        <ButtonGroup>
          <button type="submit" name="SaveButton">
            <div className="text">Salvar</div>
            <div className="icon">
              <FiCheckSquare size={24} />
            </div>
          </button>
        </ButtonGroup>
      </form>
    </Modal>
  );
}

export default ModalChangeDate;
