/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, ReactElement } from 'react';

import { RiDeleteBin5Line } from 'react-icons/ri';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { ButtonGroup } from '../../../styles/customButtonGroup';

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import Modal from '../../Modal';
import { useForm } from 'react-hook-form';
import { MaskedInput } from '../../Material/MaskedInput';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
}

interface IFormData {
  data: Date;
  cpf: string;
  agendamentoid: number;
}

type CustomErrorType = {
  response: {
    data: {
      message: string;
    };
  };
};

function ModalDeleteProcess({ isOpen, setIsOpen }: IModalProps): ReactElement {
  const { addToast } = useToast();
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [submitData, setSubmitData] = useState<IFormData>();

  const {
    register,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm<IFormData>();

  const handleConfirmDeleteClose = async () => {
    setOpenConfirmDelete(false);
  };

  async function handleDeleteProcess() {
    const formValues = getValues();

    setOpenConfirmDelete(true);
    setSubmitData(formValues);
  }

  const handleDeleteConfirmation = async (data: IFormData) => {
    if (!data || data === undefined) {
      return;
    }

    // EXTRACT DATE
    const formatDataAgendamento = String(data.data);
    const day = formatDataAgendamento.substring(0, 2);
    const month = formatDataAgendamento.substring(3, 5);
    const year = formatDataAgendamento.substring(6, 10);

    let foundCarregamentos;
    let foundPesagens;

    try {
      // Find Agendamento
      const foundAgendamentos = await api.get(
        `/agendamentos/byagendamentoid/${data.agendamentoid}`,
      );

      if (!foundAgendamentos) {
        throw new Error('Agendamento não encontrado!');
      }

      // Find Fila Carregamento
      const foundFilaCarregamento = await api.get(
        `/fila/byfilaid/${Number(foundAgendamentos.data.filaid)}`,
      );

      if (foundFilaCarregamento.data !== null) {
        if (
          foundFilaCarregamento.data.status === 'AFAT' ||
          foundFilaCarregamento.data.status === 'ADOC' ||
          foundFilaCarregamento.data.status === 'NLIB'
        ) {
          throw new Error(
            'Processo de Carregamento já finalizado, não é possível realizar a exclusão. Favor verificar.',
          );
        }
      }

      // Find Carregamento
      const carregamentoIdFound = await api.get(
        '/carregamentos/byagendamentoid',
        {
          params: {
            agendamentoid: foundAgendamentos.data.agendamentoid,
          },
        },
      );

      if (carregamentoIdFound.data.length >= 1) {
        // Find All Carregamentos por compartimentos by Carregamentoid
        foundCarregamentos = await api
          .get('/carregamentos/bycarregamentoid', {
            params: {
              carregamentoid: Number(
                carregamentoIdFound.data[0].carregamentoid,
              ),
            },
          })
          .then(response => {
            return response.data;
          });

        foundPesagens = await api.get(
          `/pesagens/bycarregamentoid/${carregamentoIdFound.data[0].carregamentoid}`,
        );
      }

      // If Exists Pesagens -> Delete
      if (foundPesagens && foundPesagens.data.length >= 1) {
        // Delete pesagem
        await api.delete(
          `/pesagens/${carregamentoIdFound.data[0].carregamentoid}`,
        );

        // Delete processo de carregamento Ikso
        const agendamentoPlaca = foundAgendamentos.data.placa;
        const agendamentoPlaca2 = foundAgendamentos.data.placa2;

        if (agendamentoPlaca2 === '' || agendamentoPlaca2 === null) {
          await api
            .delete(
              `/processikso/deleteprocessikso/${year}-${month}-${day}/${agendamentoPlaca}/""`,
            )
            .catch(result =>
              console.log('delete process IKSO placa 1', result),
            );
        }

        if (agendamentoPlaca2 !== '' && agendamentoPlaca2 !== null) {
          await api
            .delete(
              `/processikso/deleteprocessikso/${year}-${month}-${day}/${agendamentoPlaca}/${agendamentoPlaca2}`,
            )
            .catch(result =>
              console.log('delete process IKSO placa 2', result),
            );
        }
      }

      // IF exists fila -> Delete
      if (foundFilaCarregamento.data !== null) {
        // Delete Fila
        await api.delete(`/fila/${foundFilaCarregamento.data.filaid}`);
      }

      // IF exists carregamento -> Delete
      if (foundCarregamentos && foundCarregamentos.length >= 1) {
        // delete carregamentos
        await api.delete(
          `/carregamentos/${carregamentoIdFound.data[0].carregamentoid}`,
        );
      }

      // IF exists agendamentos -> Delete
      if (foundAgendamentos) {
        // delete agendamentos
        await api.delete(
          `/agendamentos/${foundAgendamentos.data.agendamentoid}`,
        );

        // delete saldo comprometido
        await api.delete(
          `/agendamentos/saldoreservado/${foundAgendamentos.data.agendamentoid}/${foundAgendamentos.data.pedido}`,
        );
      }

      // Close the modal
      setIsOpen();

      // Show Toast for success
      addToast({
        type: 'success',
        title: 'Sucesso.',
        description: `Exclusão realizada com sucesso!`,
      });

      // reset form
      reset();
    } catch (err: any) {
      let customError: CustomErrorType | null = null;
      if (!err.response?.data.message) {
        customError = {
          response: {
            data: {
              message: 'Agendamento não encontrado!',
            },
          },
        };
      }

      // Show Toast for failure
      addToast({
        type: 'error',
        title: 'Erro ao excluir processo.',
        description:
          customError !== null
            ? `${customError.response.data.message}`
            : `${err.response.data.message}`,
      });

      // Close the dialog
      setOpenConfirmDelete(false);
    }

    // Close the dialog
    setOpenConfirmDelete(false);
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} onCloseModal={() => reset()}>
      {/* CONFIRM DELETE PROCESSO */}
      <Dialog
        open={openConfirmDelete}
        onClose={handleConfirmDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirmar exclusão do processo?
        </DialogTitle>{' '}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Este processo já foi iniciado, deseja realmente realizar sua
            exclusão? Após a exclusão não será possível restaurar os dados.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDeleteClose}>Cancelar</Button>
          <Button
            style={{ color: 'red', fontWeight: '600' }}
            onClick={() => {
              handleDeleteConfirmation(submitData as IFormData);
            }}
            autoFocus
          >
            Deletar
          </Button>
        </DialogActions>
      </Dialog>

      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '1rem',
          gap: '1rem',
        }}
      >
        <h1
          style={{
            fontWeight: '500',
            fontSize: '36px',
            lineHeight: '36px',
            marginBottom: '40px',
            color: '#00a859',
            textAlign: 'center',
          }}
        >
          Deletar Processo Carregamento
        </h1>

        <MaskedInput
          {...register('data', { required: 'Data obrigatória.' })}
          control={control}
          type="date"
          name="data"
          label="Data"
          onBlur={() => {}}
          error={!!errors.data}
          autoComplete="off"
          sx={{ width: '237px' }}
        />

        <MaskedInput
          {...register('agendamentoid', { required: 'Agend. ID Obrigatorio.' })}
          control={control}
          type="none"
          name="agendamentoid"
          label="Agend. ID"
          onBlur={() => {}}
          error={!!errors.agendamentoid}
          autoComplete="off"
          sx={{ width: '237px' }}
        />

        <MaskedInput
          {...register('cpf', { required: 'CPF obrigatório.' })}
          control={control}
          type="cpf"
          name="cpf"
          label="CPF"
          onBlur={() => {}}
          error={!!errors.cpf}
          autoComplete="off"
          sx={{ width: '237px' }}
        />

        <ButtonGroup>
          <button type="button" name="SaveButton" onClick={handleDeleteProcess}>
            <div className="text">Deletar</div>
            <div className="icon">
              <RiDeleteBin5Line size={24} />
            </div>
          </button>
        </ButtonGroup>
      </form>
    </Modal>
  );
}

export default ModalDeleteProcess;
